import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/calls',
      name: 'calls',
      component: () => import('@/views/calls/index.vue')
    },
    {
      path: '/calls2',
      name: 'calls2',
      component: () => import('@/views/calls/redirect.vue')
    },
    {
      path: '/services',
      name: 'services',
      component: () => import('@/views/services/index.vue')
    },
    {
      path: '/services/add',
      name: 'services-add',
      component: () => import('@/views/services/add.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/logout.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'error-4042',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
