import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import VueSocketIO from 'vue-3-socket.io'
import SocketIO from "socket.io-client"

// Global Varaibles

//
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

//
window.api = 'https://shelp.brmja.com'
//
window.cleintvue = 'http://localhost:8080'

//
if (!localStorage.getItem('user') && window.location.pathname != '/login' && window.location.pathname != '/logout') {
  window.location = '/login';
} else {
  if (localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))) {
    $.post(window.api + '/console/auth/check', {
      jwt: JSON.parse(localStorage.getItem('user')).jwt
    }).then(function (response) {
      response = JSON.parse(response)
      if (response.status != 100 && window.location.pathname != '/logout') {
        window.location = '/logout';
      } else {
        localStorage.setItem("user", JSON.stringify(response.response))
      }
    }).catch(function () {
      if (window.location.pathname != '/logout') {
        window.location = '/logout';
      }
    })
  }
}

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')


Vue.config.productionTip = false
if (localStorage.getItem("user") && window.location.pathname == '/calls') {
  Vue.use(new VueSocketIO({
    debug: false,
    connection: SocketIO(window.api, {
      auth: {
        user: JSON.parse(localStorage.getItem("user")).jwt,
        type: "admin"
      }
    })
  }))
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
